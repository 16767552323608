import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Link } from "react-router-dom";

import { FaYoutube, FaInstagram } from "react-icons/fa";
import americanFlag from '../../imgs/paymentsFlags/americanFlag.png';
import dinnersFlag from '../../imgs/paymentsFlags/dinnersFlag.png';
import eloFlag from '../../imgs/paymentsFlags/eloFlag.png';
import hypercardFlag from '../../imgs/paymentsFlags/hypercardFlag.png';
import mastercardFlag from '../../imgs/paymentsFlags/mastercardFlag.png';
import visaFlag from '../../imgs/paymentsFlags/visaFlag.png';
import boletoFlag from '../../imgs/paymentsFlags/boletoFlag.png';
import mercadoPagoFlag from '../../imgs/paymentsFlags/mercadoPagoFlag.png';
import stamp_encryptssl from '../../imgs/stamps/stamp_encryptssl.png';

import {ContainerFooterDetails, ContainerFooterPayment, ContainerCR, PrivacyPopup} from "./style.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import dmLogo from '../../imgs/logos/dm.png';

const Footer = (  ) => {

  const [groupListFooter, setGroupListFooter] = useState([]);
  const hasAcceptedPrivacy = localStorage.getItem('hasAcceptedPrivacy');
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(!hasAcceptedPrivacy);

  const handleAcceptPrivacy = () => {
    localStorage.setItem('hasAcceptedPrivacy', 'true');
    setShowPrivacyPopup(false);
  };


  useEffect(() => {
    api
      .get("get_all_groups")
      .then((response) => {
        setGroupListFooter(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const currentYear = new Date().getFullYear();
  
  return (
    <>
      <ContainerFooterDetails>
        <Container>
          <Row>
            <Col sm={4}>
              <h2>Departamentos</h2>
              {
                groupListFooter.filter((group) => !group.ID_DM013_PAI).map((group, index) => {
                  return (
                    <p key={index}>- <Link to={`/group/${group.ID_DM013}/${group.NM_GRUPO.replace(/\//g, ' | ')}`}>
                      {group.NM_GRUPO}
                    </Link></p>
                  )
                })
              }
            </Col>
            <Col sm={4}>
              <h2>Informações</h2>
              <p>- <Link to="/aboutus">Quem Somos</Link></p>
              <p>- <Link to="/howtobuy">Como Comprar</Link></p>
              <p>- <Link to="/exchangepolicy">Politica de Compra/ Trocas/ Devoluções</Link></p>
              <p>- <Link to="/privacy-policy">Politica de Privacidade</Link></p>
            </Col>
            <Col sm={4}>
              <h2>Entre em Contato</h2>
              <p>- <Link to="">Telefone: (47) 3281-0107 | (47) 3281-0108</Link></p>
              <p>- <Link to="">WhatsApp: (47) 9 9927-4976</Link></p>
              <p>- <Link to="">pedido@elitecomandos.com.br | vendas2@elitecomandos.com.br</Link></p>
              <p>- <Link to="">Rua Fritz Lorenz, nº 1673 Galpão 1, Bairro Industrial, CEP: 89092-600.</Link></p>
            </Col>
          </Row>
        </Container>
        <p className='copy'>Elite Comandos 10.583.921/0001-88 © Todos os direitos reservados. {currentYear}</p>

      </ContainerFooterDetails>
      <ContainerCR>
        <Container>
          <Link to="https://datamais.com.br" target="_blank"><img src={dmLogo} alt=""/></Link>
        </Container>
      </ContainerCR>
      {showPrivacyPopup && (
        <PrivacyPopup>
          <p>Ao navegar por este site você aceita o uso de cookies para agilizar a sua experiência de compra.</p>
          <button onClick={handleAcceptPrivacy}>Entendi</button>
        </PrivacyPopup>
      )}

    </>
  );
}

export default Footer;
